import React, { useRef, useState } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Button from "~components/Button";
import Logo from "~components/svg/Logo";
import PaymentMethods from "~components/svg/PaymentMethods";
import { fancyError, fancyLog } from "~utils/helpers";

const query = graphql`
  query Footer {
    sanityFooter {
      content
      inputPlaceholder
      buttonText
    }
  }
`;

const Footer = () => {
  const submitRef = useRef();

  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //

  const submitProxy = () => {
    if (!submitRef?.current) {
      return;
    }

    submitRef.current.click();
  };

  const onSubmit = e => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        fancyLog(`Mailchimp Complete`);

        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (submitting || submitted) {
      return false;
    }

    setSubmitting(true);

    const mailchimpData = {
      email
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);

        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const cms = data.sanityFooter;

        //

        return (
          <footer className="footer w-full relative pt-v3 pb-v3 xs:py-10 bg-black text-white">
            <form
              className={`${
                submitting || submitted ? `pointer-events-none` : ``
              } grid xs:flex xs:flex-col xs:mb-10`}
              onSubmit={onSubmit}
            >
              <input
                ref={submitRef}
                className="w-0 h-0 absolute top-0 left-0 opacity-0 pointer-events-none"
                type="submit"
              />

              <p className="grid-end-4 b2 xs:mb-10">{cms.content}</p>

              <input
                className={`${
                  submitting || submitted
                    ? `opacity-50 pointer-events-none`
                    : ``
                } grid-end-5 grid-start-6 h-full px-4 bg-transparent border-white rounded-edge--more overflow-hidden f-button xs:w-full xs:py-4 xs:mb-5 xs:text-center`}
                onChange={e => setEmail(e.currentTarget.value)}
                placeholder={cms.inputPlaceholder}
                readOnly={submitting || submitted}
                required
                type="email"
              />

              <div
                className={`${
                  submitting || submitted
                    ? `opacity-50 pointer-events-none`
                    : ``
                } grid-end-2 h-full`}
              >
                <Button
                  className="w-full h-full relative block xs:w-full xs:py-4"
                  color="white"
                  onClick={submitProxy}
                >
                  <span className="f-button">
                    {submitted ? `Thanks!` : cms.buttonText}
                  </span>
                </Button>
              </div>
            </form>

            <div className="grid pt-v5 xs:gap-5">
              <div className="grid-end-5 xs:grid-end-12 order-0 xs:order-1 xs:flex xs:items-end xs:justify-between xs:mt-v12 xs:mb-5">
                <Link to="/">
                  <Logo
                    className="footer__logo relative block mt-v2"
                    color="white"
                  />
                </Link>

                <h3 className="hidden xs:block mb-v1 b2 text-center">
                  © PAIRE 2020
                </h3>
              </div>

              <ul className="grid-end-2 xs:grid-end-6">
                <li>
                  <Link
                    to="/faqs"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    FAQ
                  </Link>
                </li>

                <li>
                  <Link
                    to="/shipping"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    Shipping
                  </Link>
                </li>

                <li>
                  <Link
                    to="/terms"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    T’s &amp; C’s
                  </Link>
                </li>
              </ul>

              <ul className="grid-end-2 xs:grid-end-6">
                <li>
                  <Link
                    to="/contact"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    Contact
                  </Link>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/getpaire/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    Facebook
                  </a>
                </li>

                <li>
                  <a
                    href="https://instagram.com/getpaire?igshid=16oyf9i5kdr00"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="block pb-v1 xs:pt-v4 xs:pb-v4 hover-underline b2"
                  >
                    Instagram
                  </a>
                </li>
              </ul>

              <div className="grid-end-3 xs:grid-end-12 flex flex-col items-start justify-between">
                <PaymentMethods className="w-full relative block xs:mt-v8" />

                <h3 className="xs:hidden mb-v1 b2 text-center">© PAIRE 2020</h3>
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
