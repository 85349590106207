import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className, color }) => {
  return (
    <svg className={className} viewBox="0 0 65 60" fill="none">
      <path
        d="M0 36.5178C0 17.6684 13.8829 0 37.4837 0C56.0405 0 64.0463 10.1741 64.0463 23.5276C64.0463 42.377 50.1634 60 26.6088 60C8.00578 60 0 49.8259 0 36.5178ZM6.33984 35.9273C6.33984 47.1007 12.402 54.2316 26.7477 54.2316C46.3225 54.2316 57.7064 39.788 57.7064 24.0727C57.7064 12.8993 51.6443 5.76836 37.2061 5.76836C17.8163 5.76836 6.33984 20.212 6.33984 35.9273ZM27.0253 34.8372C26.6551 36.109 26.2849 37.2899 26.0072 38.1983C25.637 39.4701 25.7296 40.1968 27.1179 40.1968C28.6912 40.1968 30.3572 40.2877 32.9949 40.106C32.3471 41.9228 31.7918 43.6033 31.329 45.3293C29.0152 45.1476 14.4845 45.2385 12.3095 45.3293C12.9573 43.6033 13.5127 41.8319 14.068 40.106C15.086 40.2877 15.919 40.1968 17.0296 40.1968C18.3254 40.1968 19.1583 39.5609 19.7137 38.1075C21.1945 34.0651 24.0174 25.8441 25.128 21.8471C25.3131 20.757 25.128 20.3028 23.7397 20.3028C22.3514 20.3028 21.5184 20.3936 20.5004 20.3936C21.1482 18.5768 21.7035 16.8055 22.2589 14.9886C24.3876 15.1703 36.2343 14.9886 38.6406 14.9886C45.5358 14.9886 48.4975 17.623 48.4975 23.2097C48.4975 32.5208 41.047 35.4731 34.7997 35.4731C32.0694 35.4731 29.154 35.2006 27.0253 34.8372ZM41.8337 24.1635C41.8337 21.3475 40.1678 20.1211 37.0672 20.1211C35.5864 20.1211 34.013 20.212 31.838 20.212C30.6349 23.8001 29.4317 27.4338 28.2748 30.6586C30.0333 30.9311 31.6529 31.022 33.9667 31.022C39.0571 31.022 41.8337 28.8872 41.8337 24.1635Z"
        fill={color}
      />
    </svg>
  );
};

Logo.defaultProps = {
  className: ``,
  color: `black`
};

Logo.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default Logo;
