import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useQueryParam, StringParam } from "use-query-params";
import { AppContext } from "~context/AppContext.jsx";

const Layout = ({ children, className }) => {
  const {
    cartActive,
    menuActive,
    notificationActive,
    setFreeItemValid
  } = useContext(AppContext);

  const [freePaireToken] = useQueryParam(`paireid`, StringParam);

  useEffect(() => {
    const valid = token => {
      // todo : other validation steps, pending what they give us
      return typeof token === `string` && token !== ``;
    };

    if (valid(freePaireToken)) {
      setFreeItemValid(true);
    }
  }, [freePaireToken]);

  return (
    <>
      <main
        id="layout"
        className={`layout ${className} ${
          cartActive || menuActive
            ? `pointer-events-none`
            : `pointer-event-auto`
        } ${
          notificationActive ? `notification-active` : ``
        } transition-transform`}
      >
        {children}
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default Layout;
